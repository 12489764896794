footer{
    background: #333333;
}
.text-white-light{
    color: #dddddd !important;
}
.ptr-3-10{
    padding-right: 10px;
    padding-top: 3px;
}
a{
    text-decoration: none !important;
}
hr{
    background-color: #ededed !important;
}