.div{
    margin-left: 3%;
    margin-right: 3%;
}


  
  .relative {
    position: relative;
    width:100%;
  } 

html {
  font-family: "Open Sans", sans-serif;
}

h1 {
  color: #444;
}

 .btn {
    padding: 1em 2.1em 1.1em;
    border-radius: 3px;
    margin: 8px 8px 8px 8px;
    color: #fbdedb;
    background-color: #fbdedb;
    display: inline-block;
    background: #e74c3c;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
    font-family: sans-serif;
    font-weight: 800;
    font-size: .85em;
    text-transform: uppercase;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    -moz-box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    box-shadow: 0em -0.3rem 0em rgba(0, 0, 0, 0.1) inset;
    position: relative;
}
.btn:hover, .btn:focus {
    opacity: 0.8;
}
.btn:active {
    -webkit-transform: scale(0.80);
    -moz-transform: scale(0.80);
    -ms-transform: scale(0.80);
    -o-transform: scale(0.80);
    transform: scale(0.80);
}
.btn.block {
    display: block !important;
}
.btn.circular {
  border-radius: 50em !important;
}


/* Colours */

.gray {
    color: black;
    background-color: #d2d2d2;
}

.img_section_1{
  height: 400px;
  float: left;
}
.btn_div{
  width: 70%;
  background-color: #444;
  
}
.MuiButton-root{
  border-radius: 100px;
}

.btn2{
  width: 20%; 
  background-color: #1F8ACA;
  float: left;
}

#content_div{
  display: none;
}



.rec-dot{
  display: none;
}

.side_form{
  
  float: right;
  /* position: fixed; */
  margin-left: 66%;
  /* background-color: #1F8ACA; */
 /* position: fixed -webkit-sticky; */
}


/* #sticky{
  margin-top: -200%;
  width: 25%;
  float: right;

  margin-left: 66%;
  background-color: #1F8ACA;
  height: 400px;
  position: fixed;
  display: none;
} */

.appartment-img{
  width: 4%;
  
}

.App {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;
    width: 100%;
    margin-top: -10%;
  }

  .sticky {
    position: -webkit-sticky;
    position: fixed;
    top: 100px;
    margin-top: 10%;
    /* padding: 5px;
     */
    /* background-color: #cae8ca; */
    /* border: 2px solid #4CAF50; */
  }