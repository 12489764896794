body {
  font-family: "Rubik" !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
button,
th,
td {
  font-family: "Rubik" !important;
}
h1 {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  line-height: 39px;
  color: #333333 !important;
}
h2 {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  color: #333333 !important;
}
h3 {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 19px !important;
  color: #333333 !important;
}
h4 {
  font-family: "Rubik" !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  color: #333333 !important;
}
h5 {
  font-family: "Rubik" !important;
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 17px;
  color: #333333 !important;
}
p {
  font-family: "Rubik" !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #333333 !important;
  line-height: 32px !important;
}
label {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 39px !important;
  color: #333333 !important;
  padding: 5px 12px 0px 0 !important;
}
.filter-ui p {
  font-size: 15px !important;
}
.form-control {
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 15%);
  border-radius: 5px;
  border: none !important;
  height: 35px;
  line-height: 40px;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 15px;
  color: #333333 !important;
}
.relatedprojects h6,
.content-sep-top h6 {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 17px !important;
  color: #333333 !important;
}
.card-content h6 {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 17px !important;
  line-height: 17px !important;
  color: #333333 !important;
}
.relatedprojects p,
.content-sep-top p {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 17px !important;
  color: #333333 !important;
}
.card-content p {
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  line-height: 17px !important;
  color: #333333 !important;
}

.navigation {
  background: #ffffff !important;
}
nav ul li a,
nav ul li a:visited {
  background: #ffffff;
  color: #333333 !important;
}
nav ul li a:hover,
nav ul li a:visited:hover {
  background: #ffffff !important;
  color: #1f8aca !important;
}
.brand a img {
  width: 60px;
  height: 60px;
}
.nav-container {
  max-width: 1320px;
}
.project-detail-carousel .MuiCardContent-root {
  padding: 0 !important;
  padding-bottom: 0 !important;
}
.rec.rec-slider-container {
  margin: 0;
}
nav ul li a,
nav ul li a:visited {
  line-height: 56px !important;
}
.project-detail-carousel .rec.rec-carousel {
  position: relative;
}
.project-detail-carousel .rec.rec-arrow-left {
  position: absolute;
  top: 43%;
  left: 10%;
  z-index: 9999;
}
.project-detail-carousel .rec.rec-arrow-right {
  position: absolute;
  top: 43%;
  right: 10%;
  z-index: 9999;
}

.rec-arrow {
  background-color: #dfdfdf !important;
  color: #5e5f63 !important;
  box-shadow: none !important;
}
.bg-grey {
  background: #f8f8f8;
}
.project-detail-carousel .rec.rec-carousel-wrapper {
  display: -webkit-box;
}
.red-btn {
  background: #e9341f !important;
}
.walk-broc-btn {
  padding-top: 6px;
}
.ProjFirstSecLeftImage {
  width: 125px;
  height: 125px;
  margin-right: 15px;
  background: #ffffff;
  border-radius: 5px;
}
.ProjFirstSecLeftImage img,
.ProjFirstSecRightImage img {
  padding: 15px;
}
.btn-table {
  background-color: #1f8aca;
  border-color: #1f8aca;
  border: none !important;
  border-radius: 100px;
  color: #fff;
  padding: 5px 25px;
}

.ProjFirstSecRightImage {
  width: 125px;
  height: 125px;
  background: #ffffff;
  float: right;
  border-radius: 5px;
}
.pt-60 {
  padding-top: 60px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pt-70 {
  padding-top: 70px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pt-40 {
  padding-top: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-10 {
  padding-bottom: 10px;
}
.hr-border-bottom {
  border-bottom: 1px solid #efefef !important;
}
.configuration table {
  width: 100%;
}
.configuration table td {
  width: 50%;
}
.text-underline {
  /* border-bottom: 4px solid #E9341F; */
  color: #e9341f;
}
.text-underline-blue {
  /* border-bottom: 4px solid #1F8ACA; */
  color: #1f8aca;
}
.right-side-form {
  background: #f8f8f8;
  border-radius: 8px;
  padding: 30px 25px;
  position: sticky;
  top: 50px;
}
.btn-red {
  background: #1f8aca !important;
  color: #fff !important;
  font-family: Rubik !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  margin-top: 25px !important;
  padding: 6px 42px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  text-transform: capitalize !important;
  width: 100% !important;
  border: none;
}
.MuiPaper-elevation1 {
  box-shadow: none !important;
}
.MuiTableCell-root {
  border: 1px dashed rgba(30, 30, 30, 0.25) !important;
  font-size: 15px !important;
  padding: 12px !important;
}
.floor_img {
  background: #f8f8f8;
  border-radius: 10px;
  height: 180px;
  margin-right: 15px;
  margin-bottom: 15px;
  width: 30%;
  border: 1px solid #dfdfdf;
  object-fit: cover;
}
.amenities-card {
  padding: 0;
}
.amenities .css-46bh2p-MuiCardContent-root {
  padding: 10px !important;
}
.card-icon-bg {
  background: #f8f8f8;
  padding: 30px 50px;
  border-radius: 10px;
}
.icon-text {
  text-align: center;
  margin-top: 10px !important;
}
.amenities .rec-arrow,
.relatedprojects .rec-arrow,
.home-sec-bg .rec-arrow,
.floor-sec-images .rec-arrow {
  width: 25px !important;
  height: 25px !important;
  font-size: 14px;
  min-width: 25px !important;
  line-height: 26px;
}
.MuiButtonBase-root.MuiAccordionSummary-root {
  background: none !important;
  padding: 0 !important;
}
.MuiPaper-root {
  border: none !important;
}
.MuiAccordionDetails-root {
  border: none !important;
}
.bullet-point {
  font-size: 7px !important;
  color: #666666;
  margin-right: 5px;
  position: relative;
  top: -2px;
}
.content {
  color: #666666 !important;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 17px;
}
#panel1d-content {
  margin-left: 24px;
}
.spec-title {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 17px;
  color: #333333 !important;
}
.Mui-expanded.MuiAccordionSummary-expandIconWrapper {
  color: #ffffff;
  background: #e9341f;
  border-radius: 5px;
  padding: 5px;
}
.MuiAccordionSummary-expandIconWrapper {
  background: #f8f8f8;
  border-radius: 5px;
  padding: 5px;
}
.cardListImg {
  height: 200px !important;
  border-radius: 8px 8px 0 0;
}
.searchFilterCardListImg {
  height: 205px !important;
  border-radius: 8px 8px 0 0;
}
.projStatusbtn {
  height: 25px !important;
  background-color: #e9341f !important;
  border-radius: 5px !important;
  border-radius: 100px !important;
  color: #ffffff !important;
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  text-transform: capitalize !important;
  padding: 0px 10px !important;
}
.projStatusbtn-absolute {
  position: absolute;
  top: 15px;
  right: 15px;
}
.content-sep-top {
  padding: 15px;
  border: 1px solid #dddddd;
  border-radius: 0 0 8px 8px;
  border-top: none !important;
  background: #ffffff;
}
.slider-absolute {
  position: absolute;
  top: 20px;
  right: 15px;
}
.card {
  background: #f8f8f8 !important;
  border-radius: 8px !important;
  border: none !important;
  padding: 40px 50px !important;
}
.home-sec-bg:nth-child(odd) {
  background: #f8f8f8 !important;
}
.text-white {
  color: #ffffff !important;
}
.home-banner-form select {
  font-family: "Rubik";
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: #333333;
  height: 50px;
  border-radius: 50px 0px 0px 50px;
  border: none;
  padding: 0px 10px 0 25px;
  outline: 0;
  background: #ffffff !important;
  width: 170px !important;
}
.home-banner-form input {
  background: #ffffff;
  height: 50px;
  border-radius: 0 !important;
  font-family: "Rubik";
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #333333;
  outline: 0;
  width: 380px !important;
  border: none !important;
}
.home-banner-form button.search-btn {
  margin: 0;
  border-radius: 0 50px 50px 0;
  background: #1f8aca;
  box-shadow: none;
  font-family: "Rubik" !important;
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  height: 50px;
  text-transform: capitalize;
  color: #ffffff;
  padding: 0 30px;
  outline: 0;
  width: 150px !important;
  border: none;
}
.short-line {
  border-right: 1px solid #efefef;
  width: 10px;
  background: #ffffff;
  height: 50px;
}
.home-banner-form {
  margin-top: 20px;
}
.home-banner-form p {
  font-size: 18px !important;
}
.filter-ui select {
  border-radius: 5px;
  border: 1px solid #dddddd;
  outline: none;
  font-family: "Rubik";
  font-weight: 400;
  width: 100%;
  font-size: 15px;
  line-height: 15px;
  color: #333333;
  background: #ffffff;
  box-shadow: none;
  cursor: pointer;
  padding: 10px !important;
}
.search-filter-btn {
  color: rgb(255, 255, 255) !important;
  background: #1f8aca !important;
  box-shadow: none !important;
  font-family: "Rubik" !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  text-transform: capitalize !important;
  padding: 9px 42px !important;
  margin-top: 40px !important;
  width: 100%;
  border-radius: 100px;
  border:none;
}
.list li {
  float: none !important;
  padding: 4px 8px !important;
}
.nav-dropdown a {
  line-height: 10px !important;
  height: auto !important;
  background: transparent !important;
  color: #1e1e1e !important;
  cursor: pointer;
}
.nav-dropdown li {
  padding: 4px !important;
}
ul.nav-dropdown {
  background: #ffffff;
  border-radius: 5px;
}
.pointer {
  cursor: pointer;
}
.image-hover {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 8px 8px 0 0;
}

.image-hover img {
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.image-hover:hover img {
  transform: scale(1.05);
}

.text-decorstion p {
  text-decoration: underline !important;
  color: #1f8aca !important;
}
.modal-header {
  border-bottom: none !important;
}
.projDetCont {
  margin-left: 15px;
}
.overview-content {
  max-height: 230px;
  overflow-y: auto;
}
.about-content {
  max-height: 165px;
  overflow-y: auto;
}

.Typewriter__wrapper {
  color: #ffffff !important;
  font-size: 35px !important;
  font-weight: 600 !important;
}

.text-white-cursor {
  color: #ffffff !important;
  font-size: 32px;
}

.unit-type-and-size .MuiPaper-root,
.specification .MuiPaper-root {
  background: transparent !important;
  border-radius: 0 !important;
  border: none !important;
}
.unit-type-and-size .MuiPaper-root:hover,
.specification .MuiPaper-root:hover {
  box-shadow: none !important;
}
.floor-sec-images .cardListImg {
  height: 150px !important;
  border-radius: 8px !important;
}
.floor-sec-images .image-hover {
  width: 100%;
  overflow: hidden;
  margin: 0 auto;
  border-radius: 8px !important;
}

.bottom-social-icon {
  bottom: 25px;
  right: 90px;
  position: fixed;
  z-index: 999999;
}
.bottom-social-icon img {
  margin-left: 5px;
}
button.btn-popup-sucess {
  border-radius: 100px;
  background: #e9341f !important;
  border: none;
  width: 100px;
  color: #ffffff !important;
}
.text-red {
  color: #e9341f !important;
}
.home-location-marker {
  width: 15px !important;
  height: 15px !important;
  vertical-align: top !important;
}

.rec-carousel-item:nth-child(3n-1) svg path,
.nthclr:nth-child(3n-1) svg path {
  stroke: #e9341f !important;
}
.rec-carousel-item:nth-child(3n-2) svg path,
.nthclr:nth-child(3n-2) svg path {
  stroke: #1f8aca !important;
}
.rec-carousel-item:nth-child(3n + 0) svg path,
.nthclr:nth-child(3n + 0) svg path {
  stroke: #5e5f63 !important;
}

.rec-carousel-item:nth-child(3n-1) button.nthbg,
.nthclr:nth-child(3n-1) button.nthbg {
  background: #e9341f !important;
}
.rec-carousel-item:nth-child(3n-2) button.nthbg,
.nthclr:nth-child(3n-2) button.nthbg {
  background: #1f8aca !important;
}
.rec-carousel-item:nth-child(3n + 0) button.nthbg,
.nthclr:nth-child(3n + 0) button.nthbg {
  background: #5e5f63 !important;
}

.right-side-form h5 {
  line-height: 35px !important;
}
.vertic-top img {
  vertical-align: top !important;
}
.walk_through .modal-video-movie-wrap {
  padding-bottom: 0 !important;
}
.walk_through .modal-video-inner {
  width: 100% !important;
}
.walk_through iframe {
  width: 100%;
  height: 380px;
}
.MuiAccordionDetails-root {
  padding: 5px 10px !important;
}
.rec-carousel-item .relative {
  border-radius: 8px !important;
}

.rec-carousel-item .relative:hover {
  box-shadow: 4px 4px 15px #e5e5e5;
}
.MuiPaper-root {
  border-radius: 8px !important;
}
.MuiPaper-root:hover {
  box-shadow: 4px 4px 15px #e5e5e5 !important;
}
.MuiBox-root {
  background: transparent !important;
}
a {
  cursor: pointer;
}
.floor-sec-images .image-hover {
  border: 1px solid #dddddd;
}

nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
  content: url(/src/images/nav-arrow-down.svg) !important;
  position: relative;
  top: 4px;
}

.slick-initialized .slick-slide {
  padding: 10px;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

tbody tr:nth-child(2n),
thead tr th {
  background: transparent !important;
}

.sub-stage {
  position: absolute;
  right: 15px;
  bottom: 20px;
  background: rgb(31, 138, 202);
  color: #ffffff !important;
  border-radius: 100px;
  padding: 0 15px;
  margin: 0 !important;
}

.card:hover .rotate-hover {
  transform: rotate(-9deg);
}
.background-img {
  width: 100%;
  display: inline-block;
  transition: all 1s;
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.bg-hover-content {
  position: absolute;
  bottom: 0;
  left: 0;
}
.background-img:hover {
  background-size: 103%;
}
.map-relative {
  position: relative;
}
.map-absolute {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 9999;
  background: #fff;
  width: 325px;
  padding: 15px;
  box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
  border-radius: 2px;
}
.map-text {
  color: #5b5b5b !important;
  font-size: 12px !important;
  line-height: 20px !important;
  margin-bottom: 7px !important;
}
.map-title-text {
  color: #333333 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 600 !important;
  margin-bottom: 7px !important;
}
.view-large-map {
  color: #1a73e8 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  padding: 0 !important;
}
.directions-text {
  color: #1a73e8 !important;
  font-size: 12px !important;
  line-height: 20px !important;
  
}
.pl-10 {
  padding-left: 10px;
}
.otp-notification {
  font-size: 14px !important;
  line-height: 10px !important;
  color: #1f8aca !important;
  padding-bottom: 0px !important;
  line-height: 20px !important;
}
.background-overlay{
  position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   /* background-color: #00000075; */
}



.autocom-box{
  padding: 0;
  opacity: 0;
  pointer-events: none;
  max-height: 280px;
  overflow-y: auto;
  width: 410px !important;
  z-index: 9999;
  top: 303px;
  position: absolute;
  box-shadow: 0 0 4px #B7B7B7;
 
}

.autocom-box{
  padding: 10px 8px;
  opacity: 1;
  pointer-events: auto;
}

.autocom-box li{
  list-style: none;
  padding: 8px 12px;
  display: none;
  width: 100%;
  cursor: default;
  border-radius: 3px;
}

.autocom-box li{
  display: block;
}

.seo-h{
  font-size: 20px!important;
  font-weight: 600!important;
  color: #333 !important;
  font-family: Rubik !important;
}

ul.suggestions {
  padding: 0;
}

.view-large-map{
  background: none;
  border: none;
}

.btn-direction{
  background: none;
  border: none;
}
.project-details-load{
  position: absolute;
  top: 49%;
  left: 49%;
}

.nav-list {
  margin-top: 6px;
}
.accordion-body {
  padding-top: 0 !important;
}
.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
}
.accordion-button:not(.collapsed) {
  color: #1F8ACA !important;
  background-color: transparent !important;
  box-shadow: none !important;
}
.accordion-item {
  background-color: transparent !important;
  border-left: none !important;
  border-right: none !important;
}
.home-faq-bg{
  background : #F8F8F8;
}
.white-bg{
  background-color: #ffffff;
}

.accordion-button {
  background-color: transparent !important;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  text-align: center !important;
  height: 100px !important;
}

.btn-review {
  background: #1f8aca !important;
  color: #fff !important;
  font-family: Rubik !important;
  font-size: 17px !important;
  font-weight: 400 !important;
  margin-top: 25px !important;
  padding: 6px 42px !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
  text-transform: capitalize !important;
  width: 150px !important;
  border: none;
  border-radius:70px !important;
}

.rating-box{
  border: 1px solid #1F8ACA;
    background-color: #1F8ACA;
    color: white;
    width: 64px;
    text-align: center;
    border-radius: 8px;
    height: 30px;
}

.review-content {
  max-height: 300px;
  overflow-y: auto;
}
.review-star{
  color: #CBCBCD;
  font-size: 20px !important;
}
.rating-count{
  margin-left: 15px;
}
.rating-textarea{
  border: 1px solid #dddddd;
  border-radius: 0.25rem;
  height: 150px;
}
.review-input-css{
  border: 1px solid #dddddd !important;
  box-shadow: none !important;
}
.review-block {
  background: #F8F8F8;
  padding: 25px;
  margin-top: 20px;
  border-radius: 8px;
}

p.projDetCont {
  border: 1px solid rgb(233, 52, 31);
  padding: 0 10px;
  border-radius: 45px;
  color: rgb(233, 52, 31) !important;
}
/* Media query start */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .d-flex.home-banner-form {
    display: block !important;
    margin: 0 auto;
    width: 90%;
  }
  .short-line {
    display: none;
  }
  .home-banner-form select {
    width: 100% !important;
    border-radius: 10px !important;
  }
  .home-banner-form input {
    border-radius: 10px !important;
    width: 100% !important;
    margin-top: 15px;
  }
  .home-banner-form button.search-btn {
    width: 100% !important;
    border-radius: 10px !important;
    margin-top: 15px;
  }
  .responsive-mb-15 {
    margin-bottom: 15px;
  }
  nav ul li a,
  nav ul li a:visited {
    background: #e9341f;
    color: #ffffff !important;
  }
  nav ul li {
    padding: 0 !important;
  }
  nav ul li a,
  nav ul li a:visited {
    line-height: 15px !important;
  }
  .walk-broc-btn .css-e53awj-MuiStack-root {
    display: flex;
    flex-direction: column;
  }
  .responsive-colorButton {
    width: 100%;
  }
  .responsive-bootstrapButton {
    width: 100%;
    margin-top: 15px !important;
    margin-left: 0 !important;
    margin-bottom: 15px !important;
  }
  .ProjFirstSecRightImage {
    float: left;
  }
  .floor_img {
    width: 100%;
  }
  .background-img {
    background-size: cover !important;
  }
  .map-absolute {
    width: 94%;
  }
  .autocom-box{
    width: 350px !important;
  }
  .configuration p{
    line-break: anywhere;
  }
  .btn-review {
    width: 100% !important;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  nav ul li a,
  nav ul li a:visited {
    background: #1f8aca;
    color: #ffffff !important;
  }
  nav ul li {
    padding: 0 !important;
  }
  nav ul li a,
  nav ul li a:visited {
    line-height: 15px !important;
  }
  .norecord {
    width: 100%;
  }
  .res-d-flex {
    flex-direction: column;
  }
  .projDetCont {
    margin-left: 0px;
  }
  .ProjFirstSecLeftImage {
    margin-bottom: 15px;
  }
  nav {
    position: absolute;
    top: 0;
    z-index: 99999;
  }
  .Typewriter__wrapper {
    color: #ffffff !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 20px;
  }
  .Typewriter {
    height: 65px;
    text-align: center;
  }
  .btn-review {
    width: 100% !important;
  }
}
