.background-img{
  background-image: url("../home-banner.jpg");
  background-position: center;
}
.App {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* margin-top: -10%; */
  }
  
  /* .header{
    margin-left: 7%;
  } */
  div.sc-Axmtr .jBnCOy .rec .rec-pagination{
    display: none;
  }
  
  .sc-AxheI .cIWxKc .rec .rec-dot {
    display: none;
  }

.relative {
  position: relative;
}

 .absolute {
  position: absolute;
  top: 5px;
  right: 5px; 
 }
  /* width: 200px;
  height: 100px;
  border: 3px solid #73AD21; */

  .App {
    font-family: sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    /* margin-top: -10%; */
  }
  
  /* .header{
    margin-left: 7%;
  } */
  div.sc-Axmtr .jBnCOy .rec .rec-pagination{
    display: none;
  }
  
  .sc-AxheI .cIWxKc .rec .rec-dot {
    display: none;
  }

  .card_auto_set{
    background-color: rgb(238, 218, 218);
    width: 19%;
    float: left;
    margin-left: 5%;
}

.appartment-img{
  width: 263%;
  height: 35px;
  /* margin-left: 4%; */
}
.wrapper {
  border:1px solid #000;
  display:inline-block;
  margin-left: 35%;
}




/*the container must be positioned relative:*/
.autocomplete {
  position: relative;
  display: inline-block;
}

input {
  border: 1px solid transparent;
  background-color: #f1f1f1;
  padding: 10px;
  font-size: 16px;
}

input[type=text] {
  background-color: #ffffff;
  width: 100%;
}

input[type=submit] {
  background-color: DodgerBlue;
  color: #fff;
  cursor: pointer;
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 99;
  /*position the autocomplete items to be the same width as the container:*/
  top: 100%;
  left: 0;
  right: 0;
}

.autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff; 
  border-bottom: 1px solid #d4d4d4; 
}

/*when hovering an item:*/
.autocomplete-items div:hover {
  background-color: #e9e9e9; 
}

/*when navigating through the items using the arrow keys:*/
.autocomplete-active {
  background-color: DodgerBlue !important; 
  color: #ffffff; 
}
