.app {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .progress-container {
    height: 150px;
    width: 150px;
  }
  .action-container {
    display: flex;
    gap: 15px;
  }
  .action-container button {
    font-size: 16px;
  }

  .loader {
    border: 7px solid grey;
    border-radius: 50%;
    border-top: 7px solid white;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }